import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import SecondaryLanding from "../../../layouts/secondary-landing";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const BusinessContactUs = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-line-of-credit",
      title: "Credit Solutions"
    },
    {
      id: 3,
      active: true,
      title: "Credit Inquiry"
    }
  ];

  const SEOData = {
    title: "Small Business Credit Inquiry",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Small Business Credit Inquiry"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "WaFd Bank puts your business first. Whether you need a business line of credit or commercial real estate loan, we can help. Learn more here."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-line-of-credit/contact-us"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      },
      {
        "http-equiv": "Content-type",
        content: "text/html; charset=UTF-8"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Speak with a WaFd Business Banker</h1>
        <h2 className="text-success">Tell us a little about yourself, and we will give you a call.</h2>
        <div className="row mt-4">
          <div className="col-md-6 order-2 order-md-1">
            <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
              <input className="form-control" type="hidden" name="oid" value="00D3i000000ohXB" />
              <input
                className="form-control"
                type="hidden"
                name="retURL"
                value="https://www.wafdbank.com/business-banking/business-line-of-credit/thank-you"
              />
              {/* <!--  ----------------------------------------------------------------------  --> */}
              {/* <!--  NOTE: These fields are optional debugging elements. Please uncomment    --> */}
              {/* <!--  these lines if you wish to test in debug mode.                          --> */}
              {/* <input className="form-control" type="hidden" name="debug" value={1} /> */}
              {/* <input className="form-control" type="hidden" name="debugEmail" value="jess.kady@wafd.com" /> */}
              {/* <!--  ----------------------------------------------------------------------  --> */}
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label htmlFor="first_name">First Name</label>
                  <div className="text-sm font-italic">Required</div>
                </div>
                <input
                  className="form-control mb-2 border-radius-6"
                  required
                  id="first_name"
                  maxLength="40"
                  name="first_name"
                  type="text"
                  aria-label="First Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <input
                  className="form-control mb-2 border-radius-6"
                  id="last_name"
                  maxLength="80"
                  name="last_name"
                  type="text"
                  aria-label="Last Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company">Business Name</label>
                <input
                  className="form-control mb-2 border-radius-6"
                  id="business_name"
                  maxLength="40"
                  name="company"
                  type="text"
                  aria-label="Business Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="zip">Business Zip Code</label>
                <input
                  className="form-control mb-2 border-radius-6"
                  id="zip"
                  maxLength="20"
                  name="zip"
                  type="text"
                  aria-label="Business Zip Code"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  className="form-control mb-2 border-radius-6"
                  id="email"
                  maxLength="80"
                  name="email"
                  type="email"
                  aria-label="Email Address"
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label htmlFor="phone">Phone Number</label>
                  <div className="text-sm font-italic">Required</div>
                </div>
                <input
                  className="form-control mb-2 border-radius-6"
                  required
                  id="phone"
                  maxLength="10"
                  name="phone"
                  type="tel"
                  aria-label="Phone Number"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company">Industry</label>
                <input
                  className="form-control mb-2 border-radius-6"
                  id="industry"
                  required
                  maxLength="40"
                  name="industry"
                  type="text"
                  aria-label="Industry"
                />
              </div>
              <div className="form-group">
                <label htmlFor="revenue">What is your annual revenue?</label>
                <div className="text-sm text-muted mb-3">
                  Knowing your annual revenue will help us connect you to the right team.
                </div>
                <select
                  className="form-control custom-select-chevron border-radius-6"
                  required
                  id="00N3i00000D8gOB"
                  name="00N3i00000D8gOB"
                  title="Annual Revenue"
                >
                  <option value="none"></option>
                  <option value="less-than-100k">less than $100k</option>
                  <option value="100k-250k">$100k-$250k</option>
                  <option value="250k-1m">$250k - $1M</option>
                  <option value="1m-5m">$1M - $5M</option>
                  <option value="above-5m">&gt; $5M</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="description">Comment</label>
                <textarea className="form-control mb-2 border-radius-6" name="comment" aria-label="Comment"></textarea>
              </div>
              <input className="btn btn-primary btn-block" type="submit" name="submit" />
            </form>
          </div>
          <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/blogs/small-business/what-is-the-best-bank-for-a-small-business/thumbnail-best-bank-for-small-business-010824.jpg"
              alt={`Built for Business | Rapid Invoice Payment Solutions with FINSYNC`}
              placeholder="blurred"
              quality="100"
            />
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default BusinessContactUs;
